import styled from "@emotion/styled";
import { Button, Flex, Pagination, Table, Text } from "@mantine/core";
import { IconArrowBarToRight, IconMinus } from "@tabler/icons-react";
import useInventoriesGetQuery from "api/inventories/useInventoriesGetQuery";
import { useAuthWhoamiGet } from "api/useAuthWhoamiGet";
import { InventoryRow } from "components/pages/inventory/InventoryRow";
import { OutgoingAllForm } from "components/pages/inventory/OutgoingAllForm";
import { TransferAllForm } from "components/pages/inventory/TransferAllForm";
import { useModal } from "context/ModalStackManager";

import { useState } from "react";
import { useTranslation } from "react-i18next";

//아이템 코드, 로트 코드, 로트 이름, 로트 유효기한, 최종재고
export const Inventory = () => {
  const [activePage, setPage] = useState(1);
  const { data } = useInventoriesGetQuery({
    activePage: activePage,
    sort: "-id",
    query: true
  });
  const { data: userData, refetch } = useAuthWhoamiGet();
  const { openModal } = useModal();
  const { t } = useTranslation();
  return (
    <InventoryContainer>
      <Flex gap='xl'>
        <Button leftIcon={<IconMinus />}
          color="pink"
          size="lg"
          onClick={() => {
            refetch();
            openModal(
              <OutgoingAllForm userData={userData} />,
              null,
              t("재고 벌크 출고"),
              true
            );
          }}>{t("벌크 출고")}</Button>
        <Button color="pink"
          size="lg"
          rightIcon={<IconArrowBarToRight />}
          onClick={() => {
            refetch();
            openModal(
              <TransferAllForm userData={userData} />,
              null,
              t("재고 벌크 이동"),
              true
            )
          }}>{t("벌크 이동")}</Button>
      </Flex>
      <TableContainer>
        <Table verticalSpacing={"xl"} horizontalSpacing={"xl"}>
          <Thead>
            <Tr>
              {[
                "품목코드",
                "로트명",
                "로트유효기한",
                "위치",
                "최종 재고",
                "마감 재고",
                "마감 일시",
                "추가된 재고",
                "감소된 재고",
                "재고 변화량",
              ].map((text, index) => (
                <Th seq={index}>
                  <Text fz="md" fw={500} span>
                    {t(text)}
                  </Text>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data?.data?.rows?.map((inventory) => {
              return <InventoryRow data={inventory} />;
            })}
          </Tbody>
        </Table>
        <Flex justify={"center"} w={"100%"} mt={50}>
          <Pagination
            align="center"
            onChange={setPage}
            value={activePage}
            total={data?.data?.totalPages ?? 0}
            size="md"
            radius="sm"
          />
        </Flex>
      </TableContainer>
    </InventoryContainer>

  );
};

const Thead = styled.thead`
  width: 100%;
`;
const Tbody = styled.tbody`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  width: 100%;
`;

const Th = styled.th<{ seq?: number; flex?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  gap: 10px;
  white-space: pre-line;
  word-break: break-word;
`;

const InventoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const TableContainer = styled.div`
  height: 100%;
`;